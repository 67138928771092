import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import PortableText from 'react-portable-text';
import loadable from '@loadable/component';

import { Container, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Hero } from '../components/General/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../components/SEO';
// import { Form } from '../components/ContactForm/Form';
import FormModalContext from '../context/FormModalContext';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#4B5B69',
		lineHeight: 1.5,
		fontFamily: 'Roboto',
		'& a': {
			color: theme.workwaveBlue,
		},
	},
}));

const TermsOfUsePage = ({ data, location }) => {
	const terms = data.terms.edges[0].node;
	const classes = useStyles();

	const { formModalOpen } = useContext(FormModalContext);

	const { hero, _rawPageContent, metaDescription, metaTitle } = terms;
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />

			<Hero hero={hero} />
			<Container style={{ marginBottom: '10rem' }}>
				<Grid container>
					<PortableText
						content={_rawPageContent}
						serializers={{
							h2: ({ children }) => (
								<Typography variant='h2' color='primary'>
									{children}
								</Typography>
							),
							normal: ({ children }) => (
								<Grid item style={{ margin: '1rem 0' }}>
									<Typography variant='body1' className={classes.text}>
										{children}
									</Typography>
								</Grid>
							),
							li: ({ children }) => {
								return (
									<li
										type='a'
										className={classes.text}
										style={{ fontSize: '1.125rem' }}>
										{children}
									</li>
								);
							},
							emailPhoneLink: (props) => {
								return (
									<Typography variant='body1' className={classes.text}>
										<a href={props.emailPhoneHref} className={classes.text}>
											{props.children[0]}
										</a>
									</Typography>
								);
							},
						}}
					/>
				</Grid>
				{formModalOpen ? (
					<Form
						privacy
						noForm
						location={location}
						pardotUrl='https://go.workwave.com/l/1042281/2023-11-16/65swnn'
					/>
				) : null}
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' terms />
			</div>
		</>
	);
};

export const query = graphql`
	query TermsOfUsePageQuery {
		terms: allSanityTermsOfUse {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					_rawPageContent
				}
			}
		}
	}
`;

export default TermsOfUsePage;
